<script setup lang="ts">
import { useUrlSearchParams } from '@vueuse/core'

import { LoginMigration } from '@fond-of/ergobag-ui'
import { localizeUrl } from '@fond-of/locale'

const { callbackUrl } = useUrlSearchParams<{ callbackUrl?: string }>()

const afterLogin = () => {
  globalThis.location.href = localizeUrl(callbackUrl || '/account/orders')
}
</script>

<template>
  <LoginMigration
    source="navigation"
    @after-login="afterLogin"
  />
</template>
